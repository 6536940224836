import React, { useEffect, useState } from "react"
import { changeAccessToken, changeAuthenticated, changeUserName, changeUserEmail, changeCurrentUser } from "./Store/Slices/MSALSlice"
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser"
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, } from "@azure/msal-react"
import { getCurrentUserData } from "./services/ApiServices"
import { useDispatch, useSelector } from "react-redux"
import { loginRequest } from "./Utils/authConfig"
import { scope } from "./const"
import Routes from "./Routes"
import moment from "moment"
import "./App.scss"

import Loader from "./components/Loader/Loader"
import Header from "./components/Header/Header"

export default function App() {
  const accessToken = useSelector((state) => state.MSALAuth.accessToken)

  const isAuthenticated = useIsAuthenticated()
  const { instance, inProgress, accounts } = useMsal()

  const [isLoading, setIsLoading] = useState(true)
  const [intervalTimeForAccessToken, setIntervalTimeForAccessToken] = useState(3600000)

  const dispatch = useDispatch()

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.warn(error))
  }

  const setAccessTokenData = (accessTokenResponse) => {
    // Acquire token silent success
    const accessToken = accessTokenResponse.accessToken

    localStorage.setItem("token", accessToken)
    window.accessToken = accessToken
    window.expToken = accessTokenResponse.idTokenClaims.exp

    dispatch(changeAccessToken(accessToken))

    setIntervalTimeForAccessToken((moment(accessTokenResponse?.expiresOn).diff(moment()._d, "s") + 1) * 1000)
  }

  const handleResponse = (response) => {
    if (response) {
      setAccessTokenData(response)
    } else if (accounts && accounts?.length === 0) {
      handleLogin()
    } else if (accounts && accounts?.length === 1) {
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    } else if (accounts && accounts.length > 1) {
      // Add your account choosing logic here
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    }, intervalTimeForAccessToken)

    return () => { clearInterval(interval) }
  }, [intervalTimeForAccessToken])

  useEffect(() => {
    dispatch(changeAuthenticated(isAuthenticated))
  }, [isAuthenticated])

  useEffect(() => {
    instance.handleRedirectPromise().then(handleResponse).catch((error) => console.warn(error))
  }, [isAuthenticated, inProgress])

  const GetCurrentUser = async () => {
    setIsLoading(true)
    const response = await getCurrentUserData()
    dispatch(changeCurrentUser(response))
    dispatch(changeUserName(response?.Data?.Name ?? ""))
    dispatch(changeUserEmail(response?.Data?.Email ?? ""))
    if (!response?.Success) {
      console.error("Get Current User Error:", response)
    }
    setIsLoading(false)

    return
  }

  useEffect(() => {
    if (accessToken) {
      GetCurrentUser()
    }
  }, [accessToken])

  return (
    <>
      <AuthenticatedTemplate>
        <Header />
        {!isLoading ? (
          <Routes />
        ) : (
          <Loader />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Loader />
      </UnauthenticatedTemplate>
    </>
  )
}
