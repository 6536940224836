import React, { useEffect, useState } from "react"
import * as XLSX from "xlsx"
import "./Import.scss"
import toast from "react-hot-toast"
import { handleError } from "../../const"
import { getAllFunctions, getAllServiceCategories, getAllUsers, getProfileImages, getProjects, getServiceDesiplines, saveImportData, submitImportData } from "../../services/ApiServices"
import Loader from "../../components/Loader/Loader"
import moment from "moment"

import downArrow from "./../../assets/img/icon-chevron-down.svg"
import profile from "./../../assets/img/img-placeholder.png"
import { UserImage } from "../../components/UserImage/UserImage"

const Import = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isPromiseLoading, setIsPromiseLoading] = useState(false)
    const [isUserProfileLoading, setIsUserProfileLoading] = useState(false)

    const [allProjects, setAllProjects] = useState([])
    const [allServiceCategories, setAllServiceCategories] = useState([])
    const [allServiceDisciplines, setAllServiceDisciplines] = useState([])
    const [allFunctions, setAllFunctions] = useState([])
    const [allUsers, setAllUsers] = useState([])

    const [allTimesheets, setAllTimesheets] = useState([])
    const [userFilterData, setUserFilterData] = useState([])
    const [sequenceData, setSequenceData] = useState([])
    const [allDates, setAllDates] = useState([])
    const [accordionId, setAccordionId] = useState(null) // null || number
    const [userProfileImages, setUserProfileImages] = useState([])
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

    const [savePayload, setSavePayload] = useState([])
    const [errorPayload, setErrorPayload] = useState([])

    // Remove Duplicate User
    const removeDupeUser = (allTimesheets) => {
        const uniqueUsers = []
        const usersSet = new Set()

        allTimesheets?.length > 0 && allTimesheets.forEach((item) => {
            if (!usersSet.has(item?.Email)) {
                usersSet.add(item?.Email)
                uniqueUsers.push(item?.Email)
            }
        });

        return uniqueUsers;
    }

    useEffect(() => {
        // All Dates Sorting
        let unique = []
        allTimesheets?.length > 0 && allTimesheets?.forEach((e) => {
            if (!unique.includes(moment(e?.Date, "DD-MM-YY").format("YYYY-MM-DD"))) {
                unique.push(moment(e?.Date, "DD-MM-YY").format("YYYY-MM-DD"))
            }
        })
        setAllDates(unique.sort())

        // User Sorting
        const userFilterData = removeDupeUser(allTimesheets)?.map((u) => ({
            User: u,
            timesheets: allTimesheets?.filter((t) => t?.Email === u)
        }))
        setUserFilterData(userFilterData)
    }, [allTimesheets])

    // Sequence Sorting
    useEffect(() => {
        const sequenceSorting = userFilterData?.map((user) => {
            let seqArray = []

            if (user?.timesheets && user?.timesheets?.length > 0) {
                user?.timesheets?.forEach((timesheet) => {
                    const found = seqArray?.length > 0 ? (
                        seqArray?.find((d) => (
                            d?.timesheets[0]?.Project === timesheet?.Project &&
                            d?.timesheets[0]?.ServiceCategory === timesheet?.ServiceCategory &&
                            d?.timesheets[0]?.ServiceDiscipline === timesheet?.ServiceDiscipline &&
                            d?.timesheets[0]?.CISCServiceFunction === timesheet?.CISCServiceFunction
                        ))
                    ) : undefined

                    if (found) {
                        seqArray[found?.sequence - 1].timesheets?.push(timesheet)
                    } else {
                        seqArray.push({
                            sequence: seqArray?.length + 1,
                            timesheets: [timesheet]
                        })
                    }
                })
            }

            return ({
                User: user?.User,
                seqArray: seqArray
            })
        })

        const remainingDatesDummyData = sequenceSorting?.map((user) => ({
            ...user,
            seqArray: user?.seqArray?.map((seq) => ({
                ...seq,
                timesheets: allDates?.map((date) => {
                    if (seq?.timesheets.some((timesheet) => moment(timesheet?.Date, "DD-MM-YY").format("YYYY-MM-DD") === date)) {
                        return seq?.timesheets.find((timesheet) => moment(timesheet?.Date, "DD-MM-YY").format("YYYY-MM-DD") === date)
                    } else {
                        return {
                            Date: date,
                            Email: seq?.timesheets[0]?.Email,
                            Hours: "",
                            Project: seq?.timesheets[0]?.Project,
                            Sequence: seq?.timesheets[0]?.Sequence,
                            ServiceCategory: seq?.timesheets[0]?.ServiceCategory,
                            ServiceDiscipline: seq?.timesheets[0]?.ServiceDiscipline,
                            CISCServiceFunction: seq?.timesheets[0]?.CISCServiceFunction,
                        }
                    }
                })
            }))
        }))

        const sequenceKeyAdd = remainingDatesDummyData?.map((user) => ({
            ...user,
            seqArray: user?.seqArray?.map((seq) => ({
                ...seq,
                timesheets: seq?.timesheets?.map((timesheet) => ({
                    ...timesheet,
                    Sequence: seq?.sequence
                }))
            }))
        }))

        setSequenceData(sequenceKeyAdd)
    }, [userFilterData])

    // Week Total Time
    const weekTotalTime = (data) => {
        return data?.reduce((total, entry) => {
            const hours = entry.Hours ? parseFloat(entry.Hours.trim()) : 0
            return total + hours
        }, 0);
    }

    // User Total Time
    const calculateTotalHoursFromMultipleSets = (dataSets) => {
        return dataSets?.reduce((totalHours, dataSet) => {
            return totalHours + weekTotalTime(dataSet?.timesheets)
        }, 0);
    }

    // Save Import Data
    const onSaveClick = async () => {

        if (sequenceData?.length > 0) {
            let tempTimesheetsArray = []
            allTimesheets?.map((timesheet, index) =>
                sequenceData?.map((user) =>
                    user?.seqArray?.map((seq) =>
                        seq?.timesheets?.map((data) => {
                            if (data?.Date === timesheet?.Date &&
                                data?.Email === timesheet?.Email &&
                                data?.Hours === timesheet?.Hours &&
                                data?.Project === timesheet?.Project &&
                                data?.ServiceCategory === timesheet?.ServiceCategory &&
                                data?.ServiceDiscipline === timesheet?.ServiceDiscipline &&
                                data?.CISCServiceFunction === timesheet?.CISCServiceFunction
                            ) {
                                tempTimesheetsArray?.push({
                                    ...timesheet,
                                    Sequence: data?.Sequence,
                                    RowNo: index + 1
                                })
                            }
                        })
                    )
                )
            )

            const findId = tempTimesheetsArray?.map((timesheet) => ({
                ...timesheet,
                Project: allProjects?.find((pro) => pro?.ProjectName === timesheet?.Project)?.Id,
                ServiceCategory: allServiceCategories?.find((cate) => cate?.ServiceCategoriesName === timesheet?.ServiceCategory)?.Id,
                ServiceDiscipline: allServiceDisciplines?.find((dis) => dis?.ServiceDisciplinesName === timesheet?.ServiceDiscipline)?.Id,
                CISCServiceFunction: allFunctions?.find((func) => func?.Name === timesheet?.CISCServiceFunction)?.Id,
            }))

            const payload = findId?.map((data) => ({
                Date: moment(data?.Date, "DD-MM-YYYY").format("MM-DD-YYYY"),
                Hour: data?.Hours,
                ProjectId: data?.Project,
                ServiceCategoriesId: data?.ServiceCategory,
                ServiceDisciplinesId: data?.ServiceDiscipline,
                FunctionId: data?.CISCServiceFunction,
                User: data?.Email,
                Sequence: data?.Sequence
            }))

            const response = await saveImportData(payload)
            if (response?.Success) {
                toast.success("Data submitted successfully!")
                return true
            } else {
                toast.error("Error submitting data!")
                setErrorPayload(response?.Data.length > 0 ? response?.Data : [])
                setIsErrorModalOpen(response?.Data.length > 0)
                return false
            }
        } else {
            toast.error("Please upload file.")
        }
    }

    // Submit Import Data
    const onSubmitClick = async () => {
        const saveResp = await onSaveClick()

        if (saveResp) {
            setIsLoading(true)
            let uniqueMonths = []
            allTimesheets?.length > 0 && allTimesheets?.forEach((e) => {
                if (!uniqueMonths.includes(moment(e?.Date, "DD-MM-YY").format("YYYY-MM"))) {
                    uniqueMonths.push(moment(e?.Date, "DD-MM-YY").format("YYYY-MM"))
                }
            })

            let startingOfMonth = []
            uniqueMonths.sort().length > 0 && uniqueMonths.sort().map((month) => {
                startingOfMonth.push(moment(month).startOf('month').format('YYYY-MM-DD'))
            })

            let payload = removeDupeUser(allTimesheets).length > 0 && removeDupeUser(allTimesheets).map((user) => ({
                Date: startingOfMonth,
                User: user
            }))

            submitImportData(payload).then((response) => {
                if (response?.Success) {
                    toast.success("Data submitted successfully!")
                    setAllTimesheets([])
                } else {
                    toast.error("Error while submitting data!")
                }
                setIsLoading(false)
            })
        } else {
            console.log("error")
        }
    }

    const getUndefinedKeys = (obj) => {
        return Object.entries(obj)
            .filter(([key, value]) => value === undefined)
            .map(([key, value]) => key);
    }

    const removeKeysStartingWithBang = (data) => {
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (key.startsWith("!")) {
                delete data[key];
            }
        }
        return data;
    }

    const filterUniqueAlphabets = (data) => {
        let uniqueLetters = []
        Object.keys(data).forEach(key => {
            const alphabet = key.charAt(0);
            if (!uniqueLetters?.includes(alphabet)) {
                uniqueLetters.push(alphabet);
            }
        });

        const uniqueLettersData = { ...data };
        const remainingKeysData = { ...data };

        uniqueLetters?.forEach((letter) => {
            delete remainingKeysData[`${letter}1`];
        });

        Object.keys(remainingKeysData).forEach(key => {
            delete uniqueLettersData[key];
        });

        return { uniqueLettersData, remainingKeysData, uniqueLetters }
    }

    // data re-structure
    const readDocData = (data) => {
        const removeExtraKeys = removeKeysStartingWithBang(data)

        const { uniqueLettersData, remainingKeysData, uniqueLetters } = filterUniqueAlphabets(removeExtraKeys)

        if (uniqueLetters?.length > 0 && typeof remainingKeysData === "object" && Object.keys(remainingKeysData)?.length > 0) {
            const final = ([...Array(Math.ceil(Object.keys(remainingKeysData)?.length / uniqueLetters?.length))])?.map((_, i) => {
                let obj = {}
                uniqueLetters?.forEach((d, j) => {
                    obj = { ...obj, [`${uniqueLettersData[`${d}1`]?.w}`]: remainingKeysData[`${d}${i + 2}`]?.w }
                })
                return obj
            });
            return final
        }
    }

    // data read from import file
    const handleFileUpload = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]

        if (file) {
            setIsLoading(true)

            reader.readAsBinaryString(file)

            reader.onload = (e) => {
                const data = e.target.result
                const workbook = XLSX.read(data, { type: "binary" })
                const sheetName = workbook.SheetNames[0]
                const sheet = workbook.Sheets[sheetName]
                const parsedData = readDocData(sheet)

                if (parsedData?.map((data) =>
                    data?.hasOwnProperty("Email")
                    && data?.hasOwnProperty("CISC Service Function")
                    && data?.hasOwnProperty("Service Category")
                    && data?.hasOwnProperty("Service Discipline")
                    && data?.hasOwnProperty("Project")
                    && data?.hasOwnProperty("Hours")
                    && data?.hasOwnProperty("Date")
                )?.some((d) => d === true)) {
                    const removeSpacesFromKeys = (arr) => {
                        return arr.map(obj => {
                            return Object.keys(obj).reduce((acc, key) => {
                                const newKey = key.replace(/\s+/g, ''); // Remove all spaces
                                acc[newKey] = obj[key];
                                return acc;
                            }, {});
                        });
                    }
                    setAllTimesheets(removeSpacesFromKeys(parsedData))
                    setIsLoading(false)
                } else {
                    toast.error("Some fields are missing in the file!")
                    setAllTimesheets([])
                    setIsLoading(false)
                }
            }
        }
    }

    // Get Project, Categories, Disiplines, Functions, Users
    useEffect(() => {
        let subscribed = true;

        setIsPromiseLoading(true)
        setAllProjects([])
        setAllServiceCategories([])
        setAllServiceDisciplines([])
        setAllFunctions([])
        setAllUsers([])

        Promise.all([getProjects(), getAllServiceCategories(), getServiceDesiplines(), getAllFunctions(), getAllUsers()]).then((response) => {
            if (subscribed) {
                const projectResponse = response[0]
                const serviceCategoryResponse = response[1]
                const serviceDisiplineResponse = response[2]
                const teamsResponse = response[3]
                const userResponse = response[4]

                if (typeof projectResponse?.Data === "object" && projectResponse?.Data?.length >= 0) {
                    setAllProjects(projectResponse?.Data)
                } else {
                    handleError(projectResponse)
                }

                if (typeof serviceCategoryResponse?.Data === "object" && serviceCategoryResponse?.Data?.length >= 0) {
                    setAllServiceCategories(serviceCategoryResponse?.Data)
                } else {
                    handleError(serviceCategoryResponse)
                }

                if (typeof serviceDisiplineResponse?.Data === "object" && serviceDisiplineResponse?.Data?.length >= 0) {
                    setAllServiceDisciplines(serviceDisiplineResponse?.Data)
                } else {
                    handleError(serviceDisiplineResponse)
                }

                if (typeof teamsResponse?.Data === "object" && teamsResponse?.Data?.length >= 0) {
                    setAllFunctions(teamsResponse?.Data)
                } else {
                    handleError(teamsResponse)
                }

                if (typeof userResponse?.Data === "object" && userResponse?.Data?.length >= 0) {
                    setAllUsers(userResponse?.Data)
                } else {
                    handleError(userResponse)
                }
            }
        }).catch((error) => {
            if (subscribed) {
                handleError(error)
            }
        }).finally(() => {
            if (subscribed) {
                setIsPromiseLoading(false);
            }
        })
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            {isPromiseLoading && <Loader />}
            {/* {isUserProfileLoading && <Loader />} */}

            <div className="import-timesheet">
                <main className="import-main" id="main">
                    <div className="pagetitle mb-5">
                        <h1>Import Timesheet</h1>
                    </div>

                    <div className="btns-div d-flex justify-content-between mb-5">
                        <div className="col-6 import-div d-flex align-items-center gap-3">
                            <div className="fs-6 fw-medium">Import file :</div>
                            <div className="import-btn col-4">
                                <button type="button" className="form-control btn position-relative text-white">
                                    Upload File
                                    <input
                                        type="file"
                                        multiple={false}
                                        className="position-absolute"
                                        accept=".xlsx, .xls"
                                        onChange={(e) => handleFileUpload(e)}
                                        style={{ opacity: "0", top: "0", left: "0", bottom: "0", right: "0", height: "auto", width: "auto", zIndex: "5" }}
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="col-6 save-sbmt-div d-flex justify-content-end gap-2">
                            <div className="col-8 d-flex gap-2">
                                <div className="col save-btn">
                                    <button type="button" className="form-control btn" onClick={() => onSaveClick()}>Save</button>
                                </div>
                                <div className="col submit-btn">
                                    <button type="button" className="form-control btn" onClick={() => onSubmitClick()}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            {sequenceData && sequenceData?.length > 0 ?
                                sequenceData?.map((user, userIndex) =>
                                    <table key={userIndex} className="userTable table tableCustom table-hover valignM tableUsers tableHours">
                                        <tbody>
                                            <tr className="userHeader">
                                                <td className="header-arrow-btn">
                                                    <button
                                                        className={`btn btn-primary-w btn-icon-gray d-flex justify-content-center align-items-center p-0 me-2 shadow0 btnToggle${accordionId === userIndex ? " btn-border" : ""}`}
                                                        onClick={() => setAccordionId((prev) => prev !== userIndex ? userIndex : null)}
                                                    >
                                                        <img src={downArrow} alt="down-arrow" />
                                                    </button>
                                                </td>
                                                <td className="user-profile txt-medium" colSpan="6" onClick={() => setAccordionId((prev) => prev !== userIndex ? userIndex : null)}>
                                                    <div className="nav-profile d-flex align-items-center pe-0">
                                                        <UserImage className="rounded-circle profile-width me-3" email={user?.User} size={40} border="none" />
                                                        <span>
                                                            {(allUsers && allUsers?.length > 0 && allUsers?.find((u) => u?.Email === user?.User)?.Name) ?? "--"}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-end pe-3" onClick={() => setAccordionId((prev) => prev !== userIndex ? userIndex : null)}>
                                                    <span>{calculateTotalHoursFromMultipleSets(user?.seqArray).toFixed(2)}</span>
                                                    {" "}
                                                    <span className="txt-gray">Hrs.</span>
                                                </td>
                                            </tr>
                                            <tr className={`listing table-row${accordionId === userIndex ? "" : " d-none"}`}>
                                                <td colSpan="10" className="p-0">
                                                    <div className="divApproval">
                                                        <table className="table tableCustom valignM tableHours">
                                                            <thead className="table-date-header">
                                                                <tr className="header-row" align="center">
                                                                    <th scope="col" colSpan="7" align="left">Projects</th>
                                                                    {allDates && allDates?.length > 0 &&
                                                                        allDates?.map((date, dateIndex) =>
                                                                            <th key={dateIndex} scope="col">
                                                                                <span className="week">{moment(date).format("ddd")}</span>
                                                                                <span className="day">{moment(date).format("MMM DD")}</span>
                                                                            </th>
                                                                        )
                                                                    }
                                                                    <th scope="col" colSpan="6">&nbsp;</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="table-date-body">
                                                                {user?.seqArray && user?.seqArray?.length > 0 &&
                                                                    user?.seqArray?.map((seq, seqIndex) =>
                                                                        <tr key={seqIndex} align="center" className="mb-2 seq-row">
                                                                            <td className="body-project-col" colSpan="7">
                                                                                <div className="project-div d-flex align-items-center text-start px-3">
                                                                                    <div className="ms-2">
                                                                                        <div className="project-display fw-medium p-0">
                                                                                            {allServiceCategories && allServiceCategories?.length > 0
                                                                                                ? allServiceCategories?.find((cate) => cate?.ServiceCategoriesName === seq?.timesheets[0]?.ServiceCategory)?.ServiceCategoriesName
                                                                                                ?? "--"
                                                                                                : "--"
                                                                                            }{" - "}
                                                                                            {allServiceDisciplines && allServiceDisciplines?.length > 0
                                                                                                ? allServiceDisciplines?.find((disc) => disc?.ServiceDisciplinesName === seq?.timesheets[0]?.ServiceDiscipline)?.ServiceDisciplinesName
                                                                                                ?? "--"
                                                                                                : "--"
                                                                                            }
                                                                                        </div>
                                                                                        <div className="comments-display text-black-50 p-0">
                                                                                            {allProjects && allProjects?.length > 0
                                                                                                ? allProjects?.find((pro) => pro?.ProjectName === seq?.timesheets[0]?.Project)?.ProjectName
                                                                                                ?? "--"
                                                                                                : "--"
                                                                                            }{" - "}
                                                                                            {allFunctions && allFunctions?.length > 0
                                                                                                ? allFunctions?.find((func) => func?.Name === seq?.timesheets[0]?.CISCServiceFunction)?.Name
                                                                                                ?? "--"
                                                                                                : "--"
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            {seq?.timesheets && seq?.timesheets?.length > 0 &&
                                                                                seq?.timesheets?.map((timesheet, tIndex) =>
                                                                                    <td key={tIndex}>
                                                                                        <span className="week">1</span>
                                                                                        <input type="text" className="form-control form2" value={timesheet?.Hours} disabled />
                                                                                    </td>
                                                                                )}
                                                                            <td>&nbsp;</td>
                                                                            <td colSpan="3">
                                                                                <span>
                                                                                    {weekTotalTime(seq?.timesheets).toFixed(2)}
                                                                                </span>
                                                                                &nbsp;&nbsp;
                                                                                <span className="txt-gray">Hrs.</span>
                                                                            </td>
                                                                            <td>&nbsp;</td>
                                                                            {/* <td align="right">
                                                                            <button type="submit" className="btn lock-btn" onClick={() => unlockSeq([seq])}>Unlock</button>
                                                                        </td> */}
                                                                        </tr>
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )
                                : <div className="mt-4">
                                    <div className="d-flex justify-content-center fs-3">No Data</div>
                                </div>
                            }
                        </div>
                    </div>
                </main>

                {/* Error Modal */}
                <div className={`modal fade error-Modal ${isErrorModalOpen ? "show d-block" : "d-none"}`} id="checktimesheet" tabIndex={-1}>
                    <div className="modal-dialog p-0 d-flex justify-content-center align-items-center">
                        <div className="modal-content modal-custom-content">
                            <div className="modal-custom-header">
                                <div>
                                    <h2 className="">Error Table</h2>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        onClick={() => setIsErrorModalOpen(false)}
                                        aria-label="Close"
                                    />
                                </div>
                            </div>

                            <table className="table tableCustom valignM">
                                <thead className="tHead">
                                    <tr className="header-row" align="center">
                                        <th scope="col">Row</th>
                                        <th scope="col">User</th>
                                        <th scope="col">Error</th>
                                    </tr>
                                </thead>

                                <tbody className="tBody">
                                    {errorPayload.length > 0 && errorPayload.map((data, index) =>
                                        <tr key={index} align="center" className="mb-2 seq-row">
                                            <td>{data?.RowNo}</td>
                                            <td>{data?.User}</td>
                                            <td>
                                                {data?.Error}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <div className="btn-div text-end">
                                <button className="btn save-btn w-25" onClick={() => setIsErrorModalOpen(false)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Import