import React, { useEffect, useState } from "react"
import { changeCurrentUser, changeUserEmail, changeUserName } from "./Store/Slices/MSALSlice"
import { Route, Routes as DomRouter } from "react-router-dom"
import { getCurrentUserData } from "./services/ApiServices"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { app_version } from "./const"

import Projects from "./pages/Projects/Projects"
import Function from "./pages/Function/Function"
import ServiceDiscipline from "./pages/ServiceDiscipline/ServiceDiscipline"
import ServiceCategories from "./pages/ServiceCategories/ServiceCategories"
import User from "./pages/User/User"
import Preference from "./pages/Preference/Preference"
import ApprovalStatus from "./pages/ApprovalStatus/ApprovalStatus"
import RedirectScreen from "./pages/RedirectScreen/RedirectScreen"
import Holidays from "./pages/Holidays/Holidays"
import Import from "./pages/Import/Import"
import ImportHolidays from "./pages/ImportHolidays/ImportHolidays"
import PreferredLocation from "./pages/PreferredLocation/PreferredLocation"
import Dashboard from "./pages/Dashboard/Dashboard"
import Notifications from "./pages/Notifications/Notifications"
import Periods from "./pages/Periods/Periods"

import Error from "./components/Error/Error"
import Sidebar from "./components/Sidebar/Sidebar"
import Loader from "./components/Loader/Loader"

export default function Routes() {
  const { Data, ExceptionMessage, Message } = useSelector((state) => state.MSALAuth.currentUser)

  // const [checkedOneTime, setCheckedOneTime] = useState(false)

  // const dispatch = useDispatch()

  // useEffect(() => {
  //   let subscribed = true;

  //   (async () => {
  //     if (!checkedOneTime && (!Data?.UPN || !Data?.IsActive)) {
  //       const response = await getCurrentUserData()
  //       if (subscribed) {
  //         if (response?.Data?.UPN && response?.Data?.IsActive) {
  //           setCheckedOneTime(false)
  //         } else {
  //           setCheckedOneTime(true)
  //           console.error("Get Current User Error:", response)
  //         }
  //         dispatch(changeCurrentUser(response))
  //         dispatch(changeUserName(response?.Data?.Name ?? ""))
  //         dispatch(changeUserEmail(response?.Data?.Email ?? ""))
  //       }
  //     }

  //     return
  //   })()

  //   return () => {
  //     subscribed = false
  //   }
  // }, [checkedOneTime, Data?.UPN, Data?.IsActive])

  // useEffect(() => {
  //   if (!Data?.UPN || !Data?.IsActive) {
  //     window.location.href = "/"
  //   }
  // }, [Data?.UPN, Data?.IsActive])

  return (
    <>
      {Data?.IsAdmin && <Sidebar />}
      <DomRouter>
        {Data?.UPN && Data?.IsActive ? (
          <>
            {/* <Route index element={<NewTimesheet />} /> */}
            <Route path="/" element={<Navigate to="/timesheet" />} />
            <Route path="/timesheet" element={<RedirectScreen />} />
            <Route path="/favourite" element={<Preference />} />
            <Route path="/preferred-location" element={<PreferredLocation />} />
            <Route path="/report" element={<Dashboard />} />
            {Data?.IsAdmin && (
              <>
                <Route path="/projects" element={<Projects />} />
                <Route path="/function" element={<Function />} />
                <Route path="/service-discipline" element={<ServiceDiscipline />} />
                <Route path="/service-categories" element={<ServiceCategories />} />
                <Route path="/users" element={<User />} />
                <Route path="/approval" element={<ApprovalStatus />} />
                <Route path="/import-timesheet" element={<Import />} />
                <Route path="/holidays" element={<Holidays />} />
                <Route path="/import-holidays" element={<ImportHolidays />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/period" element={<Periods />} />
              </>
            )}
            <Route path="*" element={<Error Status={404} Message={"The page you are looking for doesn't exist."} />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}

        {(!Data?.UPN || !Data?.IsActive) && (
          <Route
            path="/"
            element={(
              !Data?.UPN ? (
                <Error Status={401} Message={ExceptionMessage || Message} />
              ) : !Data?.IsActive && (
                <Error Status={400} Message={"Bad Request. User is inactive, please contact admin."} />
              )
            )}
          // element={!checkedOneTime ? (
          //   <Loader />
          // ) : (
          //   !Data?.UPN ? (
          //     <Error Status={401} Message={ExceptionMessage || Message} />
          //   ) : !Data?.IsActive && (
          //     <Error Status={400} Message={"Bad Request. User is inactive, please contact admin."} />
          //   )
          // )}
          />
        )}
      </DomRouter>
      <div className="text-end" style={{ position: "fixed", bottom: 0, width: "100%", color: "gray", padding: "5px 10px" }}>
        {app_version}
      </div>
    </>
  )
}
