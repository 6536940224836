import React, { useEffect, useMemo } from "react"
import { iconCiSC } from "../../assets/img/icons"
import { useDispatch, useSelector } from "react-redux"
import { loginRequest } from "../../Utils/authConfig"
import { useMsal } from "@azure/msal-react"
import { changeAuthenticated, changeUserImage } from "../../Store/Slices/MSALSlice"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import "./Header.scss"

import iconUser from "../../assets/img/img-placeholder.png"
import { getProfileImages } from "../../services/ApiServices"

export default function Header() {
    const profileImg = useSelector((state) => state.MSALAuth.userImage)
    const { Data } = useSelector((state) => state.MSALAuth.currentUser) ?? {}
    const { instance, accounts } = useMsal()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const account = useMemo(() => (
        accounts?.length > 0 ? accounts[0] : {}
    ), [accounts])

    const handleLogout = () => {
        instance.logoutRedirect(loginRequest)
        dispatch(changeAuthenticated(false))
    }

    const toggleSideBar = () => {
        if (Data?.IsAdmin) {
            const body = document.getElementById("body")
            if (body) {
                if (body?.classList.contains("toggle-sidebar")) {
                    body?.classList.remove("toggle-sidebar")
                } else {
                    body?.classList.add("toggle-sidebar")
                }
            }
        }
    }

    // Get user profile
    useEffect(() => {
        let subscribed = true

        dispatch(changeUserImage({}))
        if (account?.username) {
            getProfileImages(account?.username).then((response) => {
                dispatch(changeUserImage(response))
            }).catch((error) => {
                dispatch(changeUserImage({}))
            })
        }

        return () => {
            subscribed = false
        }
    }, [account?.username])


    useEffect(() => {
        const body = document.getElementById("body")
        if (!Data?.IsAdmin) {
            if (!body?.classList.contains("toggle-sidebar")) {
                body?.classList.add("toggle-sidebar")
            }
        } else {
            if (body?.classList.contains("toggle-sidebar")) {
                body?.classList.remove("toggle-sidebar")
            }
        }
    }, [Data?.IsAdmin])

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="header-inner d-flex align-items-center">
                {Data?.IsAdmin && (
                    <i className="fa-solid fa-bars toggle-sidebar-btn pe-3" onClick={() => toggleSideBar()} />
                )}
                <div className="d-flex align-items-center justify-content-between">
                    <a href="/" className="logo d-flex align-items-center">
                        {/* <img className="logo-sec" src={iconCiSC} alt="" /> */}
                        {iconCiSC}
                    </a>
                </div>
            </div>
            <div className="header-date align-items-center offset-xl-4 date">
                <span>{moment().format("MMMM D, YYYY, hh:mm A")}</span>
            </div>
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    <li className="nav-item dropdown">
                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="/" data-bs-toggle="dropdown">
                            <img src={profileImg ?? iconUser} alt="Profile" className="rounded-circle" />
                            <span className="d-md-block dropdown-toggle pe-2">Hi, <span>{Data?.Name ?? account?.name ?? "--"}</span></span>
                            <button className="dropdown">
                                <i className="icon">
                                    <i className="fa-solid fa-chevron-down" />
                                </i>
                            </button>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{Data?.Name ?? account?.name ?? "--"}</h6>
                                <span>{Data?.Email ?? account?.username ?? "--"}</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            {Data?.Email && (
                                <>
                                    <li>
                                        <div onClick={() => navigate("/favourite")} className="dropdown-item d-flex align-items-center">
                                            <i className="bi bi-person" />
                                            <span>Favourites</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div onClick={() => navigate("/preferred-location")} className="dropdown-item d-flex align-items-center">
                                            <i className="bi bi-person" />
                                            <span>Preferred Location</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div onClick={() => navigate("/report", { state: { key: false } })} className="dropdown-item d-flex align-items-center">
                                            <i className="bi bi-person" />
                                            <span>Report</span>
                                        </div>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    {/* <li>
                                <a className="dropdown-item d-flex align-items-center" href="/">
                                    <i className="bi bi-gear" />
                                    <span>Account Settings</span>
                                </a>
                            </li> */}
                                    {/* <li>
                                <hr className="dropdown-divider" />
                            </li> */}
                                </>
                            )}
                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="#" onClick={() => handleLogout()}>
                                    <i className="bi bi-box-arrow-right" />
                                    <span>Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </header>
    )
}
